@import "~@/styles/variables/variables.scss";














.container {
	height: 100%;
}
